import { getCookie } from "./helpers";

export const alias_name = "Star Hub Green";
export const color = "#451BAB";

export const devModeEnabled = getCookie("dev_mode");
export const apiHost = "https://api-shg-bms.katomaran.tech"

export const types = [
    "grab",
    "goJek",
    "gomfort",
    "grans-cab",
    "SMRT",
    "premier",
    "prime",
    "HDT",
    "yellow cab",
    "silver sab",
    "LIMO",
    "Others",
];
export const Deliverytypes = [
    "Grab food",
    "Food panda",
    "Deliveroo",
    "Mcdonalds",
    "Redmart",
    "NinjaVan",
    "Fed Ex",
    "UPS",
    "Zalora",
    "Urban Fo",
    "Lala Move",
    "NTUC",
    "CJ Logistic",
    "DHL",
    "Amazon",
    "Q-xpress",
    "Gaint",
    "IKEA",
    "JNT",
    "EMART",
    "Forty Two",
    "Aramex",
    "Others",
];